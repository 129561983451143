import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes
} from '@angular/router';
import { DowntimeModule } from './error/downtime/downtime.module';
import { ForbiddenModule } from './error/forbidden/forbidden.module';
import { GeneralErrorModule } from './error/general-error/general-error.module';
import { NotFoundModule } from './error/not-found/not-found.module';

const routes: Routes = [
  {
    path: '404',
    loadChildren: () => NotFoundModule
  },
  {
    path: 'error',
    loadChildren: () => GeneralErrorModule
  },
  {
    path: 'forbidden',
    loadChildren: () => ForbiddenModule
  },
  {
    path: 'downtime',
    loadChildren: () => DowntimeModule
  },
  {
    path: 'dashboard',
    // canActivate: [IsAuthenticatedGuard/*, IsModeratorGuard*/], // not all agencies or admin users are [moderators]
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'collab',
    /*canActivate: [IsAuthenticatedGuard],*/
    loadChildren: () => import('@collab/modules/public/public.module').then(m => m.PublicModule)
  },
  {
    path: 'login',
    // canActivate: [IsAnonymousGuard],
    data: {
      loginBasePath: '/login'
    },
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'experience',
    // loadChildren: () => import('./event/experience/event.module').then(m => m.EventModule)
    redirectTo: 'glasshouse'
  },
  {
    path: 'glass-house',
    redirectTo: 'glasshouse'
  },
  {
    path: 'glass_house',
    redirectTo: 'glasshouse'
  },
  {
    path: 'glasshouse',
    loadChildren: () => import('./event/glass-house/glass-house.module').then(m => m.GlassHouseModule)
  },
  {
    path: '',
    loadChildren: () => import('./network/network.module').then(m => m.NetworkModule)
  },
  {
    path: '**',
    redirectTo: '/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes/*, {
   initialNavigation: 'enabledBlocking'
   }*/)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

import { HttpClient } from '@angular/common/http';
import {
  Inject,
  Injectable,
  Optional
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  makeStateKey,
  TransferState
} from '@angular/platform-browser';
import statusCode from '@assets/status-code.json';
import { environment } from '@environments/environment';
import { utilsFactory } from '@factories/utils.factory';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { RouterService } from '@services/router/router.service';
import Axios from 'axios';
import JsFileDownloader from 'js-file-downloader';
import { CacheService } from '../cache/cache.service';

export interface ResourceRequestInterface {
  resource?: string;
  path?: string;
  params?: object;
  preventDuplicates?: boolean;
  data?: object | Array<object>;
  fromCache?: boolean;
  fromSSR?: boolean;
  token?: boolean;
  authorization?: boolean;
  removeHeaders?: string[];
  expiration?: { minutes?: number; hours?: number; days?: number };
  showError?: boolean;
  filename?: string;
  headers?: {
    'Authorization'?: string,
    'Api-Token'?: string,
    'Cache-Control'?: string,
    'device-uuid'?: string,
    'user-country'?: string,
    'accept-language'?: string
  };
  onSuccess?: () => any;
  onError?: () => any;
  onFinished?: () => any;
}

@Injectable({
  providedIn: 'root'
})
export class ResourceService {

  static instance: ResourceService;

  _deviceUUID = null;
  _userCountry = null;

  _ssrTransferData = null;
  _getRequestControl = {};
  _postRequestControl = {};
  _hasCallOnceControl = {};
  _authorizationTokenByApi = {};

  constructor(
    private http: HttpClient,
    private cacheService: CacheService,
    private snackBar: MatSnackBar,
    private router: RouterService,
    private transferState: TransferState,
    @Optional() @Inject('USER_COUNTRY') userCountry: string,
    @Optional() @Inject(REQUEST) private request: any
  ) {
    // console['logger'].log('resource.service->constructor(): this._authorizationTokenByApi', this._authorizationTokenByApi);
    ResourceService.instance = this;

    if (!userCountry) {
      const USER_COUNTRY = makeStateKey<string>('USER_COUNTRY');
      userCountry = transferState.get(USER_COUNTRY, environment.defaultCountry);
    }

    this._userCountry = userCountry;
    // console['logger'].log('resource.service->constructor(): this._userCountry', this._userCountry);

    // console['logger'].log('resource.service->constructor(): environment', environment);

    if (this.request) {
      // this.request.headers['accept-language'];
      // console['logger'].log('resource.service->constructor(): this.request.headers', this.request.headers['accept-language']);
    }

    // tslint:disable-next-line:forin
    for (const key in environment.apis) {
      this._authorizationTokenByApi[key] = null;
    }

    // console['logger'].log('resource.service->constructor(): this._authorizationTokenByApi', this._authorizationTokenByApi);

    this._deviceUUID = this.cacheService.getSession('device-uuid', 'GC_USER_DEVICE_UUID');
    // console['logger'].log('resource.service->constructor(): this._deviceUUID', this._deviceUUID);

  }

  /**
   * Method to get the JSON transclude from SSR
   */
  getSsrTransferData() {
    try {
      if (utilsFactory.isBrowser) {

        if (!this._ssrTransferData) {
          this._ssrTransferData = JSON.parse(document.getElementById('Influencers-Web-state').innerHTML.replace(/&q;/g, '"'));
          // console['logger'].log('resource.service->constructor(): this._ssrTransferData', this._ssrTransferData);
        }

        return this._ssrTransferData;
      }
    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method to get the data from the json that was transclude from SSR
   */
  getResourceFromSsrData(api, options): any {
    // console['logger'].log('resource.service->getResourceFromSsrData(): api, options', api, options);

    try {
      const ssrData = this.getSsrTransferData();
      // console['logger'].log('resource.service->getResourceFromSsrData(): ssrData', ssrData);

      const url = this.getApiUrl(api, options);
      // console['logger'].log('resource.service->getResourceFromSsrData(): url', url);

      for (const i in ssrData) {
        if (i.indexOf(url) > -1) {
          return ssrData[i].body;
        }
      }

      return null;

    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method to get an authorization token of an API
   */
  getAuthorizationToken(apiName: string): string {
    try {
      // console['logger'].log('resource.service->getAuthorizationToken(): apiName', apiName, this._authorizationTokenByApi);

      if (apiName in this._authorizationTokenByApi) {
        return this._authorizationTokenByApi[apiName];
      }
      else {
        // throw new Error(`The '${apiName}' is not recognized as an 'apiName'. The api names available are: ${this._authorizationTokenByApi}`);
        return null;
      }
    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method to set the Authorization token
   */
  setAuthorizationToken(apiName: string | string[], token: string, bearer = false) {
    try {
      // console['logger'].log('resource.service->setAuthorizationToken(): apiName', apiName, token);

      if (!token) {
        throw new Error('The token must be provided.');
      }

      if (typeof token !== 'string') {
        throw new Error('The token must be a string.');
      }

      const setApiTokenByApiName = (apiNameStg) => {
        if (apiNameStg in this._authorizationTokenByApi) {
          this._authorizationTokenByApi[apiNameStg] = (bearer ? 'Bearer ' : '') + token;
          // console['logger'].log('resource.service->setAuthorizationToken(): this._authorizationTokenByApi[apiName', apiName, this._authorizationTokenByApi[apiName]);
        }
        else {
          throw new Error(`The '${apiNameStg}' is not recognized as an 'apiName'. The api names available are: ${this._authorizationTokenByApi}`);
        }
      };

      if (Array.isArray(apiName)) {
        // tslint:disable-next-line:forin
        for (const i in apiName) {
          setApiTokenByApiName(apiName[i]);
        }
      }
      else if (typeof apiName === 'string') {
        setApiTokenByApiName(apiName);
        /*if (apiName in this._authorizationTokenByApi) {
         this._authorizationTokenByApi[apiName] = (bearer ? 'Bearer ' : '') + token;
         // console['logger'].log('resource.service->setAuthorizationToken(): this._authorizationTokenByApi[apiName', apiName, this._authorizationTokenByApi[apiName]);
         }
         else {
         throw new Error(`The '${apiName}' is not recognized as an 'apiName'. The api names available are: ${this._authorizationTokenByApi}`);
         }*/
      }

      // console['logger'].log('resource.service->setAuthorizationToken(): this._authorizationTokenByApi', apiName, this._authorizationTokenByApi);

    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method to set the Authorization token
   */
  removeAuthorizationToken(apiName: string) {
    try {
      // console['logger'].log('resource.service->removeAuthorizationToken(): apiName', apiName);

      if (apiName === 'all') {
        // tslint:disable-next-line:forin
        for (const i in this._authorizationTokenByApi) {
          this._authorizationTokenByApi[i] = null;
        }
      }
      else if (apiName in this._authorizationTokenByApi) {
        this._authorizationTokenByApi[apiName] = null;
        // console['logger'].log('resource.service->removeAuthorizationToken():this._authorizationTokenByApi[apiName]', apiName, this._authorizationTokenByApi[apiName]);
      }
      else {
        throw new Error(`The '${apiName}' is not recognized as an 'apiName'. The api names available are: ${this._authorizationTokenByApi}`);
      }

    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Set the deviceUUID - Available just within app webview
   */
  setDeviceUUID(deviceUUID: string) {
    this._deviceUUID = deviceUUID;
    this.cacheService.setSession('device-uuid', 'GC_USER_DEVICE_UUID', this._deviceUUID);
    console['logger'].log(`resource.service->setDeviceUUID(): this._deviceUUID`, this._deviceUUID);
  }

  /**
   * Set the userCountry
   */
  setUserCountry(userCountry: string) {
    this._userCountry = userCountry;
    console['logger'].log(`resource.service->setUserCountry(): this._userCountry`, this._userCountry);
  }

  /**
   * Get request headers
   */
  getDefaultHeaders(api: string, options: ResourceRequestInterface) {
    try {

      const removeHeaders = options.removeHeaders || [];

      if ((
          removeHeaders.indexOf('authorization') === -1 &&
          removeHeaders.indexOf('Authorization') === -1
        ) &&
        options.authorization !== false &&
        this.getAuthorizationToken(api)
      ) {
        options.headers = { ...options.headers || {}, Authorization: this.getAuthorizationToken(api) };
      }
      // console['logger'].log(`resource.service->getDefaultHeaders(): options.headers`, options.headers);

      if (
        removeHeaders.indexOf('device-uuid') === -1 &&
        this._deviceUUID
      ) {
        options.headers = { ...options.headers || {}, 'device-uuid': this._deviceUUID };
      }

      if (
        removeHeaders.indexOf('user-country') === -1 &&
        this._userCountry
      ) {
        options.headers = { ...options.headers || {}, 'user-country': this._userCountry };
      }

      if (this.request && this.request.headers['accept-language']) {
        options.headers = { ...options.headers || {}, 'accept-language': this.request.headers['accept-language'] };
      }

      // console['logger'].log(`resource.service->getDefaultHeaders(): options.headers`, options.headers);

      if (!options.headers) {
        options.headers = {};
      }

      // options.headers['Accept-Language2'] = 'pt'; removing this line because is causing CORs "Access-Control-Allow-Headers" error!!!

      return options.headers;

    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method to return the resource API final URL
   */
  getApiUrl(api: string, options: ResourceRequestInterface): string {
    try {
      // console['logger'].log('resource.service->getApiUrl(): options', api, options);

      if (!options) {
        throw new Error(`'options' must be an Object`);
      }

      let url = environment.apis[api];
      // console['logger'].log('resource.service->getApiUrl(): url', api, url);

      if (!url) {
        throw new Error(`'${api}' does not exist on 'environment.apis'`);
      }

      if (!options) {
        throw new Error(`'options' must be an Object`);
      }

      /*if (!options.path) {
       throw new Error('PATH must be provided');
       }*/

      if (options.path) {
        url += options.path;
      }

      if (typeof options.params === 'object') {
        const queryParams = `?${utilsFactory.serializeObject(options.params)}`;
        // console['logger'].log('resource.service->getApiUrl(): url:options.params', api, url, queryParams);

        url += queryParams;
      }

      // console['logger'].log('resource.service->getApiUrl(): url', api, url);

      return url;

    }
    catch (e) {
      console.error('resource.service->getApiUrl(): ERROR', e);
      throw e;
    }
  }

  /**
   * Method to deal with request errors
   */
  errorHandler(errorObj, showError = true) {
    try {
      console['logger'].log(`resource.service->errorHandler(): errorObj`, errorObj, errorObj.response.status);
      // console['logger'].log(`resource.service->errorHandler(): statusCode`, statusCode);

      let message = statusCode[errorObj.response.status] ? statusCode[errorObj.response.status].message : 'There was an error!!!';
      console['logger'].log(`resource.service->errorHandler(): message 1`, message);

      if (errorObj.response) {
        if (errorObj.response.error && errorObj.response.error.message) {
          message = errorObj.response.error.message;
        }
        else if (errorObj.response.message) {
          message = errorObj.response.message;
        }
      }

      console['logger'].log(`resource.service->errorHandler(): message 2`, message);

      if (errorObj.response && errorObj.response.status !== 401 && errorObj.response.status !== 404) {

        utilsFactory.noticeError(new Error(message), errorObj);

        if (showError) {
          this.snackBar.open(message, 'Ok', {
            panelClass: 'mip-color--bg-danger',
            horizontalPosition: 'end'
          });
        }

      }

      if (
        message &&
        utilsFactory.isBrowser &&
        (
          ['local', 'qa'].indexOf(environment.environmentName) > -1 ||
          window.localStorage.getItem('mip-force-enable-log') === 'true'
        )
      ) {

        /*
         this.snackBar.open(message, 'Ok', {
         panelClass: 'mip-color--bg-danger',
         horizontalPosition: 'end'
         });
         */

      }
    }
    catch (e) {
      console.error('resource.service->errorHandler(): ERROR', e);
    }
  }

  /**
   * Method to get a source from localStorage/API
   */
  async get(api: string, options: ResourceRequestInterface, logs = false): Promise<any> {
    // console['logger'].log('resource.service->get(): api', api, options);

    const url = this.getApiUrl(api, options);

    if (logs) {
      console['logger'].log(`resource.service->get(): url`, url);
    }

    // console.time(`[TIME] Resource.get() ${url}`);

    try {

      let finalUrl = url;

      if (logs) {
        console['logger'].log(`resource.service->get(): finalUrl: ${url}`, finalUrl);
      }

      // console.trace(`[TRACE] Resource.get() ${finalUrl}`);

      // let forceUpdateIfNotCached = false;

      if (logs) {
        console['logger'].log(`resource.service->get(): this._getRequestControl[url]: ${url}`, !!this._getRequestControl[url], options.fromCache);
      }

      if (!this._getRequestControl[url]) {
        this._getRequestControl[url] = new Promise(async (resolve, reject) => {

          if (logs) {
            console['logger'].log(`resource.service->get(): new Promise(): ${url}`);
          }

          const dataFromCache = this.cacheService.getCache(options.resource, url);

          if (logs) {
            console['logger'].log(`resource.service->get(): dataFromCache: ${url}`, !!dataFromCache);
            console['logger'].log(`resource.service->get(): this._hasCallOnceControl[url]: ${url}`, this._hasCallOnceControl[url]);
            console['logger'].log(`resource.service->get(): fromCache: ${url}`, environment.forceCache !== true, (options.fromCache === false || environment.disableCache === true));
          }

          if (environment.forceCache !== true && (options.fromCache === false || environment.disableCache === true)) {
            finalUrl = url + ((url.indexOf('?') > -1 ? `&` : '?') + `cb=${Date.now()}`);

            if (logs) {
              console['logger'].log(`resource.service->get(): CACHE: Cache not enabled for [${options.resource}]: ${finalUrl}`);
            }

          }
          else if (utilsFactory.isBrowser && this._hasCallOnceControl[url] === undefined && !dataFromCache) {
            this._hasCallOnceControl[url] = true;
            finalUrl = url; // + ((url.indexOf('?') > -1 ? `&` : '?') + `cb=csr`);

            if (options.fromSSR === false) {
              finalUrl += ((url.indexOf('?') > -1 ? `&` : '?') + `cb=csr`);
            }
          }
          else {

            if (logs) {
              console['logger'].log(`resource.service->get(): CACHE: Trying get cache for [${options.resource}]: ${url}`);
            }

            if (dataFromCache) {

              // `this._getRequestControl[url]` will be defined after the `return` itself,
              // therefore, delaying the removal is NECESSARY!!!
              setTimeout(() => {
                delete this._getRequestControl[url];
              }, 10);

              return resolve(dataFromCache);

            }
            else {
              if (logs) {
                console['logger'].log(`resource.service->get(): CACHE: Cache NOT available for [${options.resource}]: ${finalUrl}`);
              }
            }

          }

          /*if (options.authorization !== false && this.getAuthorizationToken(api)) {
           options.headers = {...options.headers || {}, Authorization: this.getAuthorizationToken(api)};
           }*/

          if (logs) {
            console['logger'].log(`resource.service->get(): GETTING NEW CONTENT [${options.resource}]: ${finalUrl}`, options.headers);
          }

          // console.time(`[TIME] Resource.get() ${finalUrl}`);

          const headers = this.getDefaultHeaders(api, options);

          if (logs) {
            console['logger'].log(`resource.service->get(): headers`, headers);
          }

          if (logs) {
            console['logger'].log(`resource.service->get(): finalUrl`, finalUrl);
          }

          this.http.get(finalUrl, {
            headers
          }).subscribe(
            // @ts-ignore
            (response: { items?, success?, message? }) => {

              delete this._getRequestControl[url];

              if (response && response.success === false) {

                const error = new Error(response.message);
                console.error(`resource.service->get(): ERROR`, error);

                response['status'] = 500;
                response['error'] = error;

                this.errorHandler({
                  url,
                  options,
                  method: 'GET',
                  response
                }, options.showError);

                reject(error);
                return;

              }

              // console.timeEnd(`[TIME] Resource.get() ${finalUrl}`);

              let setCache = true;

              if (logs) {
                console['logger'].log(`resource.service->get(): this._getRequestControl[url]: ${url} RESPONSE`);
                console['logger'].log(`resource.service->get(): response`, finalUrl, response);
              }

              if (
                response &&
                (
                  (Array.isArray(response.items) && response.items.length === 0) ||
                  !Array.isArray(response.items)
                )
              ) {

                if (logs) {
                  console['logger'].log(`resource.service->get(): NOT SET CACHE. [items] is === 0`, finalUrl, response);
                }

                setCache = false;
              }

              if (response && setCache) { /* && (options.fromCache !== false && environment.disableCache !== true)*/

                if (logs) {
                  console['logger'].log(`resource.service->get(): setCache`, url, {fromCache: (options.fromCache !== false && environment.disableCache !== true)});
                }

                this.cacheService.setCache(options.resource, url, response, options.expiration);
              }

              resolve(response);

            },
            (error) => {

              console.error(`resource.service->get(): ERROR`, url, error);

              delete this._getRequestControl[url];

              this.errorHandler({
                url,
                options,
                method: 'GET',
                response: error
              }, options.showError);

              error.error.code = error.status;
              console.error(`resource.service->get(): ERROR`, url, error.error.code, error);

              reject(error.error);

            }
          );

        });
      }
      else {
        console['logger'].warn(`resource.service->get(): THERE IS ALREADY A PENDING REQUEST FOR ${url}`);
      }

      return this._getRequestControl[url];

    }
    catch (e) {
      console.error(`resource.service->get(): ERROR`, e);

      delete this._getRequestControl[url];
      throw e;
    }
  }

  /**
   * Method to POST to a BE service
   */
  async post(api: string, options: ResourceRequestInterface): Promise<any> {

    console['logger'].log('resource.service->post(): options', options);

    const url = this.getApiUrl(api, options);

    const requestPromise = (resolve, reject) => {

      try {

        if (options.data && typeof options.data !== 'object') {
          throw new Error(`'options.data' must be an OBJECT or an ARRAY.`);
        }

        /*if (this.getAuthorizationToken(api) && options.token !== false) {
         options.headers = {...options.headers || {}, Authorization: this.getAuthorizationToken(api)};
         }*/

        const headers = this.getDefaultHeaders(api, options);
        console['logger'].log(`resource.service->post(): headers`, headers);

        this.http.post(
          url,
          options.data,
          {
            headers
          }
        ).subscribe((response: { success?, message? }) => {

          delete this._postRequestControl[url];

          if (response && response.success === false) {

            const error = new Error(response.message);
            console.error(`resource.service->post(): ERROR`, error);

            response['status'] = 500;
            response['error'] = error;

            this.errorHandler({
              url,
              options,
              method: 'GET',
              response
            }, options.showError);

            reject(error);
            return;

          }

          if (options.resource) {
            this.cacheService.clearCache(options.resource);
          }

          // console.log('resource.service->post(): SUCCESS', response);
          resolve(response);
        }, (error) => {

          // console['logger'].log('resource.service->post(): ERROR', error);

          this.errorHandler({
            url,
            options,
            method: 'POST',
            response: error
          }, options.showError);

          delete this._postRequestControl[url];

          reject(error.error);

        });

      }
      catch (e) {
        if (e instanceof Error) {

          utilsFactory.noticeError(new Error(e.message), {
            url,
            options,
            method: 'POST',
            error: e
          });

          delete this._postRequestControl[url];

          reject(e);

        }
      }
    };

    console['logger'].log('resource.service->post(): options.preventDuplicates', url, options.preventDuplicates, !!this._postRequestControl[url]);

    if (options.preventDuplicates) {
      if (!this._postRequestControl[url]) {
        this._postRequestControl[url] = new Promise(requestPromise);
      }
      else {
        console['logger'].warn(`resource.service->post(): THERE IS ALREADY A PENDING REQUEST FOR ${url}`);
      }
    }

    if (this._postRequestControl[url]) {
      return this._postRequestControl[url];
    }
    else {
      return new Promise(requestPromise);
    }

  }

  /**
   * Method to PUT to a BE service
   */
  async put(api: string, options: ResourceRequestInterface): Promise<any> {

    console['logger'].log('resource.service->put(): options', options);

    return new Promise((resolve, reject) => {

      const url = this.getApiUrl(api, options);

      try {

        if (options.data && typeof options.data !== 'object') {
          throw new Error(`'options.data' must be an OBJECT or an ARRAY.`);
        }

        /*if (this.getAuthorizationToken(api)) {
         options.headers = {...options.headers || {}, Authorization: this.getAuthorizationToken(api)};
         }*/

        const headers = this.getDefaultHeaders(api, options);
        console['logger'].log(`resource.service->put(): headers`, headers);

        this.http.put(
          url,
          options.data,
          {
            headers
          }
        ).subscribe((response: { success?, message? }) => {

          if (response && response.success === false) {

            const error = new Error(response.message);
            console.error(`resource.service->put(): ERROR`, error);

            response['status'] = 500;
            response['error'] = error;

            this.errorHandler({
              url,
              options,
              method: 'GET',
              response
            }, options.showError);

            reject(error);
            return;

          }

          if (options.resource) {
            this.cacheService.clearCache(options.resource);
          }

          // console['logger'].log('resource.service->put(): SUCCESS', response);
          resolve(response);
        }, error => {

          this.errorHandler({
            url,
            options,
            method: 'PUT',
            response: error
          }, options.showError);

          // console['logger'].log('resource.service->put(): ERROR', error);
          reject(error.error);

        });

      }
      catch (e) {
        // console.error(e);
        if (e instanceof Error) {

          utilsFactory.noticeError(new Error(e.message), {
            url,
            options,
            method: 'PUT',
            error: e
          });

          reject(e);

        }
      }
    });
  }

  /**
   * Method to DELETE to a BE service
   */
  async delete(api: string, options: ResourceRequestInterface): Promise<any> {

    console['logger'].log('resource.service->delete(): options', options);

    return new Promise((resolve, reject) => {

      const url = this.getApiUrl(api, options);

      try {

        /*if (this.getAuthorizationToken(api)) {
         options.headers = {...options.headers || {}, Authorization: this.getAuthorizationToken(api)};
         }*/

        const headers = this.getDefaultHeaders(api, options);
        console['logger'].log(`resource.service->delete(): headers`, headers);

        this.http.delete(url, {
          headers
        }).subscribe((response: { success?, message? }) => {

          if (response && response.success === false) {

            const error = new Error(response.message);
            console.error(`resource.service->delete(): ERROR`, error);

            response['status'] = 500;
            response['error'] = error;

            this.errorHandler({
              url,
              options,
              method: 'GET',
              response
            }, options.showError);

            reject(error);
            return;

          }

          if (options.resource) {
            this.cacheService.clearCache(options.resource);
          }

          // console['logger'].log('resource.service->delete(): SUCCESS', response);
          resolve(response);
        }, error => {
          // console['logger'].log('resource.service->delete(): ERROR', error);

          this.errorHandler({
            url,
            options,
            method: 'DELETE',
            response: error
          }, options.showError);

          // console['logger'].log('resource.service->delete(): ERROR', error);
          reject(error.error);

        });

      }
      catch (e) {
        // console.error('resource.service->delete(): ERROR', e);

        if (e instanceof Error) {

          utilsFactory.noticeError(new Error(e.message), {
            url,
            options,
            method: 'DELETE',
            error: e
          });

          reject(e);

        }
      }

    });

  }

  /**
   * Method to LINK
   */
  async link(api: string, options: ResourceRequestInterface): Promise<any> {
    console['logger'].log(`resource.service->link(): api`, api, options);

    return new Promise(async (resolve, reject) => {

      const url = this.getApiUrl(api, options);

      try {

        /*if (this.getAuthorizationToken(api)) {
         options.headers = {...options.headers || {}, Authorization: this.getAuthorizationToken(api)};
         }*/

        // console['logger'].log('resource.service->link(): options`, options);

        const headers = this.getDefaultHeaders(api, options);
        console['logger'].log(`resource.service->link(): headers`, headers);

        const response: { success?, message? } = await Axios.request({
          url,
          method: 'link',
          headers
        });

        if (response && response.success === false) {

          const error = new Error(response.message);
          console.error(`resource.service->link(): ERROR`, error);

          response['status'] = 500;
          response['error'] = error;

          this.errorHandler({
            url,
            options,
            method: 'GET',
            response
          }, options.showError);

          reject(error);
          return;

        }

        if (options.resource) {
          this.cacheService.clearCache(options.resource);
        }

        // console['logger'].log('resource.service->link(): response`, response);

        resolve(response);

      }
      catch (e) {

        this.errorHandler({
          url,
          options,
          method: 'LINK',
          response: e
        }, options.showError);

        reject(e);

      }

    });
  }

  /**
   * Method to UNLINK
   */
  async unlink(api: string, options: ResourceRequestInterface): Promise<any> {
    return new Promise(async (resolve, reject) => {

      // console['logger'].log('resource.service->unlink(): api`, api, options);

      const url = this.getApiUrl(api, options);

      try {

        /*if (this.getAuthorizationToken(api)) {
         options.headers = {...options.headers || {}, Authorization: this.getAuthorizationToken(api)};
         }*/

        const headers = this.getDefaultHeaders(api, options);
        console['logger'].log(`resource.service->unlink(): headers`, headers);

        const response: { success?, message? } = await Axios.request({
          url,
          method: 'unlink',
          headers
        });

        if (response && response.success === false) {

          const error = new Error(response.message);
          console.error(`resource.service->unlink(): ERROR`, error);

          response['status'] = 500;
          response['error'] = error;

          this.errorHandler({
            url,
            options,
            method: 'GET',
            response
          }, options.showError);

          reject(error);
          return;

        }

        if (options.resource) {
          this.cacheService.clearCache(options.resource);
        }

        // console['logger'].log('resource.service->unlink(): response`, response);

        resolve(response);

      }
      catch (e) {

        this.errorHandler({
          url,
          options,
          method: 'UNLINK',
          response: e
        }, options.showError);

        reject(e);
      }

    });
  }

  /**
   * Method to DOWNLOAD a file
   */
  async downloadFile(api: string, options: ResourceRequestInterface, logs = false): Promise<any> {
    // console.log('resource.service->downloadFile(): api', api, options);

    const url = this.getApiUrl(api, options);

    if (logs) {
      console.log(`resource.service->downloadFile(): url`, url);
    }

    try {

      let finalUrl = url;

      if (logs) {
        console.log(`resource.service->downloadFile(): finalUrl: ${url}`, finalUrl);
      }

      const headers = this.getDefaultHeaders(api, options);

      if (logs) {
        console.log(`resource.service->downloadFile(): headers`, headers);
      }

      const newHeaders = [];

      if (Object.keys(headers).length) {
        for (let i in headers) {
          newHeaders.push({ name: i, value: headers[i] });
        }
      }

      if (logs) {
        console.log(`resource.service->downloadFile(): newHeaders`, newHeaders);
      }

      return new JsFileDownloader({
        url: finalUrl,
        filename: options.filename,
        headers: newHeaders
      });

    }
    catch (e) {
      console.error(`resource.service->downloadFile(): ERROR`, e);
      throw e;
    }
  }

}

import {
  BackOfficeUserInterface,
  BaseModel,
  UserInterface,
  UserSettingsInterface,
  UserSettingsModel
} from '@api/models/';
import { utilsFactory } from '@factories/utils.factory';
import { CacheService } from '@services/cache/cache.service';
import { ModelFactoryService } from '@services/model-fectory/model-factory.service';
import { ResourceService } from '@services/resource/resource.service';

import moment from 'moment';

const randomMC = require('random-material-color');

export class UserModel extends BaseModel implements UserInterface {

  apiToken = null; // api
  refreshToken = null; // api

  id = null; // api
  name = null; // api
  email = null; // api
  username = null; // api
  bio = null; // api
  isFollowing = false; // api
  website = null; // api
  phone = null; // api
  settings: Array<UserSettingsModel> = []; // api
  referralCode = null; // api
  isActive = null; // api
  gender = null; // api

  userStats = {
    followers: 0,
    following: 0,
    posts: 0,
    reactionsReceived: 0,
    reactionsReceivedList: [],
    author: 0,
    instagram: '',
    views: 0,
    engagement: 0,
    avg_engagement: 0,
    avg_views_post: 0,
    interactions: 0,
    reach: 0
  }; // api

  monetizationSocialMedia = {
    social_media: null,
    user: null,
    followers: null
  }; // api

  isChosenOne = null; // api
  isChosenPlus = null; // api
  isAmbassador = null; // api
  isModerator = null; // api
  hasIdentityVerified = null; // api

  hasAcceptedMonetizationToS = null; // api
  hasAcceptedWalletToS = null; // api

  roles = []; // api
  rolesEvents = []; // api

  avatarUrl = null; // api
  agency = null; // api
  referralUser = null; // apiÏ

  backofficeUser: BackOfficeUserInterface = null;

  profileUrl = null; // local
  isUnderage = null; // local
  hasJustFollowed = null; // local
  hasJustUnfollowed = null; // local
  avatar = null; // local;
  isAuthenticated = null; // local

  randomBgColor = randomMC.getColor({ shades: ['400', '900'] }); // local

  constructor(model?: UserInterface) {
    // console['logger'].log('user.model->constructor(): model', model);
    super(model);

    // console['logger'].log('user.model->constructor(): emailSetting', emailSetting);

    if (model.apiToken && model.apiToken.length < 40) {
      model.apiToken = null;
    }

    this.fill(model);

  }

  override beforeFill(data) {
    // console['logger'].log('user.model->beforeFill(): data', data.username, data);

    if (this.isModerator === true) {
      data.isModerator = true;
    }

    if (this.isChosenOne) {
      data.isChosenOne = true;
    }
    if (this.isChosenPlus) {
      data.isChosenPlus = true;
    }
    if (this.isAmbassador) {
      data.isAmbassador = true;
    }
    if (this.isModerator) {
      data.isModerator = true;
    }

    if (data.apiToken && data.apiToken.length < 40) {
      data.apiToken = null;
    }

    if (data.avatarUrl) {
      data.avatar = ModelFactoryService.instance.avatarFactory.build({
        id: data.id,
        source: data.avatarUrl
      });
    }

    if (data.agency) {
      data.agency = ModelFactoryService.instance.userFactory.build(data.agency);
    }

    if (data.referralUser) {
      data.referralUser = ModelFactoryService.instance.userFactory.build(data.referralUser);
    }

    data.userStats = { ...this.userStats, ...(data.userStats || {}) };

    // console['logger'].log('user.model->beforeFill(): this.userStats', this.username, this.userStats);
    // console['logger'].log('user.model->beforeFill(): data.userStats', this.username, data.userStats);

    // todo: workaround to resolve the problem of the `author` within the post does NOT bring the right count of `reactionsReceived`
    if (data.userStats && this.userStats.reactionsReceived > 0 && data.userStats.reactionsReceived === 0) {
      data.userStats.reactionsReceived = this.userStats.reactionsReceived;
    }

    if (data.userStats && (this.userStats.reactionsReceivedList && this.userStats.reactionsReceivedList.length > 0) && (data.userStats.reactionsReceivedList && data.userStats.reactionsReceivedList.length === 0)) {
      data.userStats.reactionsReceivedList = this.userStats.reactionsReceivedList;
    }

    // Implementing a working around due to API no keeping consistence oj the settings array.
    // sometimes it does not return all settings, sometimes it does not return settings at all
    if (Array.isArray(data.settings) && data.settings.length) {

      const newSettings = this.settings;

      for (const setting of data.settings) {
        const settingModel = this.settings.filter(item => item.id === setting.id)[0];
        // console['logger'].log('user.model->beforeFill(): settingModel', data.username, settingModel);

        if (!settingModel) {
          newSettings.push(setting);
        }
      }
      // console['logger'].log('user.model->beforeFill(): settingModel', data.username, newSettings);

      data.settings = ModelFactoryService.instance.userSettingsFactory.buildCollection(newSettings);
      // console['logger'].log('user.model->beforeFill(): settingModel', data.username, data.settings.length, data.settings);

    }

    // Implementing a working around due to API no keeping consistence of 'roles' array type. Sometimes the
    // API returns a object instead Array. Making sure the `roles` property is always an array
    if (data.roles && (Array.isArray(data.roles) || typeof data.roles === 'object')) {

      const roles = [...this.roles];

      // tslint:disable-next-line:forin
      for (const i in data.roles) {
        if (roles.indexOf(data.roles[i]) === -1) {
          roles.push(data.roles[i]);
        }
      }

      data.roles = roles;
      // console['logger'].log('user.model->beforeFill(): data.roles', data.username, data.roles);

    }

    // Implementing a working around due to API no keeping consistence of 'rolesEvents' array type. Sometimes the
    // API returns a object instead Array. Making sure the `rolesEvents` property is always an array
    if (data.rolesEvents && (Array.isArray(data.rolesEvents) || typeof data.rolesEvents === 'object')) {

      const rolesEvents = [...this.rolesEvents];

      // tslint:disable-next-line:forin
      for (const i in data.rolesEvents) {
        if (rolesEvents.indexOf(data.rolesEvents[i]) === -1) {
          rolesEvents.push(data.rolesEvents[i]);
        }
      }

      data.rolesEvents = rolesEvents;
      // console['logger'].log('user.model->beforeFill(): data.rolesEvents', data.username, data.rolesEvents);

    }

    if (data.monetizationSocialMedia) {
      data.monetizationSocialMedia = { ...this.monetizationSocialMedia, ...data.monetizationSocialMedia };
    }

    // console['logger'].log('user.model->beforeFill(): this.monetizationSocialMedia', model.username, this.monetizationSocialMedia);

  }

  override afterFill(model) {

    // console['logger'].log('user.model->afterFill(): model', this.username, this);

    if (this.backofficeUser) {
      this.backofficeUser = ModelFactoryService.instance.dashboardUserFactory.build(this.backofficeUser);
    }

    if (this.settings) {

      this.isUnderage = moment(this.getSettings('birthday')).isAfter(moment().subtract(18, 'years'));
      // console['logger'].log('user.model->afterFill(): this.isUnderage', this.username, this.isUnderage);

      const name = this.settings.find(item => item.key === 'name');
      this.name = name ? name.value : this.name;

      this.profileUrl = this.getSettings('url');
      // console['logger'].log('user.model->afterFill(): this.profileUrl', this.profileUrl);

    }

  }

  addRole(role: string) {
    if (this.roles.indexOf(role) === -1) {
      this.roles.push(role);
    }
  }

  getAge() {

    if (this.getSettings('birthday')) {
      return moment().diff(this.getSettings('birthday'), 'years');
    }

    return null;

  }

  /**
   * method to return the avatar source img
   */
  getAvatarSource() {
    try {
      if (this.avatar) {
        return this.avatar.source;
      }
    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method to return a value from a given key
   */
  getSettings(key: string, settings?: Array<any>) {
    // console['logger'].log('user.model->getSettings(): key', key);

    let finalSettings = null;

    settings = this.settings || settings;
    // console['logger'].log('user.model->getSettings(): settings', settings);

    // tslint:disable-next-line:forin
    for (const i in settings) {

      // console['logger'].log('user.model->getSettings(): settings[i].visibility', key, settings[i].visibility, this.isAuthenticated);
      // if (settings[i].visibility === 'public' || this.isAuthenticated) {
      if (key) {
        // console['logger'].log('user.model->getSettings(): settings[i].visibility', key, settings[i]['key'], key === settings[i]['key']);

        if (key === settings[i]['key']) {
          finalSettings = settings[i]['value'];
          break;
        }
      }
      else {

        if (!finalSettings) {
          finalSettings = {};
        }

        finalSettings[settings[i]['key']] = settings[i]['value'];
      }
      // }
    }

    // console['logger'].log('user.model->getSettings(): finalSettings', finalSettings);

    if (key === 'url' && typeof finalSettings === 'string') {
      if (finalSettings.indexOf('http') === -1) {
        finalSettings = `http://${finalSettings}`;
      }

      if (utilsFactory.validateUrl(finalSettings)) {
        return finalSettings;
      }
      else {
        return null;
      }
    }

    return finalSettings;

  }

  getSettingById(id: number, settings?: Array<any>) {
    console['logger'].log('user.model->getSettingById(): id', id);

    try {

      if (!id) {
        throw new Error(`Setting 'id' must be provided`);
      }

      settings = this.settings || settings;
      // console['logger'].log('user.model->getSettings(): settings', settings);

      for (const i in settings) {
        if (id === settings[i]['id']) {
          return settings[i];
        }
      }

      return null;

    }
    catch (e) {
      throw e;
    }

  }

  getSettingByKey(key: string, settings?: Array<any>) {
    console['logger'].log('user.model->getSettingByKey(): key', key);

    try {

      if (!key) {
        throw new Error(`Setting 'key' must be provided`);
      }

      settings = this.settings || settings;
      // console['logger'].log('user.model->getSettings(): settings', settings);

      for (const i in settings) {
        if (key === settings[i]['key']) {
          return settings[i];
        }
      }

      return null;

    }
    catch (e) {
      throw e;
    }

  }

  /**
   * Method to compare a setting value to a value provided
   */
  isSettings(key: string, value: any) {
    // console['logger'].log('user.model->getSettings(): key', key, this);
    return this.getSettings(key) === value;
  }

  /**
   * Method to get languages from user settings
   */
  getLanguages(key?): { content_language: Array<string> | string, application_language: string } {

    if (key) {
      return this.getSettings(key);
    }

    return {
      application_language: this.getSettings('application_language'),
      content_language: this.getSettings('content_language') || []
    };
  }

  /**
   * Method to return the user name from settings object
   */
  getName() {
    try {
      // const userName = this.settings.find(item => item.key === 'name');
      // console['logger'].log('user.model->getName(): userName', this);
      return this.name; // userName.value;
    }
    catch (e) {
      return null;
    }
  }

  /**
   * Method to return the user firs name from settings object
   */
  getFirstName() {
    try {
      const userName = this.getName();
      return userName.split(' ')[0];
    }
    catch (e) {
      return null;
    }
  }

  /**
   * Method to return the user firs name from settings object
   */
  getLastName() {
    try {
      const userName = this.getName();
      const splitName = userName.split(' ');

      if (splitName.length === 1) {
        return '';
      }

      return splitName[splitName.length - 1];
    }
    catch (e) {
      return null;
    }
  }

  /**
   * Method to return the user social media networks from settings object
   */
  getNetworks() {
    try {
      const userName = this.settings.find(item => item.key === 'social_media');
      return JSON.parse(userName.value);
    }
    catch (e) {
      return null;
    }
  }

  /**
   * Method to get the user birthday
   */
  getBirthday() {
    return this.getSettings('birthday');
  }

  /**
   * Method to get the user birthday
   */
  isUserOlderThen(age: number) {
    return this.getSettings('birthday');
  }

  /**
   * Method to get the user gender
   */
  getGender() {
    return this.getSettings('gender');
  }

  /**
   * Method to validate if the user has signed up for some event/party
   */
  hasSignedInEvent(eventRuleName) {

    if (!eventRuleName) {
      throw new Error(`'eventRuleName' must be provided`);
    }

    const slugName = eventRuleName.toUpperCase().replace(/\s|-|\./g, '_');

    // ROLE_EVENT_SIGNIN_{eventName}
    const ruleName = `ROLE_EVENT_SIGNIN_${slugName}`;
    // console['logger'].log('user.model->hasSignedInEvent(): ruleName', this.rolesEvents, ruleName, this.rolesEvents.indexOf(ruleName) > -1);

    return this.rolesEvents.indexOf(ruleName) > -1;

  }

  /**
   * Method to validate if the user has signed up for some event/party
   */
  hasCheckedInEvent(eventRuleName, isLocalCheckIn?: boolean) {

    if (!eventRuleName) {
      throw new Error(`'eventRuleName' must be provided`);
    }

    const slugName = eventRuleName.toUpperCase().replace(/\s|-|\./g, '_');

    // ROLE_EVENT_CHECKIN_{LOCAL_}{eventName}
    const ruleName = `ROLE_EVENT_CHECKIN_${isLocalCheckIn ? 'LOCAL_' : ''}${slugName}`;
    // console['logger'].log('user.model->hasCheckedInEvent(): ruleName', this.rolesEvents, ruleName, this.rolesEvents.indexOf(ruleName) > -1);

    return this.rolesEvents.indexOf(ruleName) > -1;

  }

  /**
   * Method to validate if the user has validated his/her network followers number
   */
  isNetworkValidated(eventRuleName) {

    if (!eventRuleName) {
      throw new Error(`'eventRuleName' must be provided`);
    }

    const slugName = eventRuleName.toUpperCase().replace(/\s|-|\./g, '_');

    // ROLE_EVENT_NETWORK_VALIDATED_{eventName}
    const ruleName = `ROLE_EVENT_NETWORK_VALIDATED_${slugName}`;
    console['logger'].log('user.model->isNetworkValidated(): ruleName', this.rolesEvents, ruleName, this.rolesEvents.indexOf(ruleName) > -1);

    return this.rolesEvents.indexOf(ruleName) > -1;

  }

  /**
   * Method to validate if the user has been qualified for a competition
   */
  hasQualifiedForCompetition(eventRuleName, competitionName) {

    if (!eventRuleName) {
      throw new Error(`'eventRuleName' must be provided`);
    }

    if (!competitionName) {
      throw new Error(`'competitionName' must be provided`);
    }

    const slugName = eventRuleName.toUpperCase().replace(/\s|-|\./g, '_');

    competitionName = competitionName.replace(/\s|-/g, '_');
    competitionName = competitionName.toUpperCase();
    console['logger'].log('user.model->hasQualifiedForCompetition(): competitionName', competitionName);

    // ROLE_EVENT_VALIDATED_{competitionName}_{eventName}
    const ruleName = `ROLE_EVENT_VALIDATED_${competitionName}_${slugName}`;
    console['logger'].log('user.model->isNetworkValidated(): ruleName', this.rolesEvents, ruleName, this.rolesEvents.indexOf(ruleName) > -1);

    return this.rolesEvents.indexOf(ruleName) > -1;
  }

  /**
   * Method to verify if the user has validated his/her social media
   */
  checkHasInstagramSocialMedia(eventRuleName, competitionName) {
    try {
      console['logger'].log('user.model->checkHasInstagramSocialMedia()', { eventRuleName, competitionName });

      const socialMediaSetting = this.getSettings('glasshouse_social_media_instagram');
      // console['logger'].log('user.model->checkHasInstagramSocialMedia(): socialMediaSetting', socialMediaSetting);

      if (
        this.monetizationSocialMedia.social_media === 'instagram' &&
        this.monetizationSocialMedia.followers
      ) {
        console['logger'].log('user.model->checkHasInstagramSocialMedia(): this.monetizationSocialMedia', this.monetizationSocialMedia);
        return true;
      }
      else if (socialMediaSetting) {
        console['logger'].log('user.model->checkHasInstagramSocialMedia(): socialMediaSetting', socialMediaSetting);
        return true;
      }

      return false;

    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method to validate if the user has accept event terms
   */
  hasAcceptEventTerms(eventRuleName, competitionName) {
    // console['logger'].log('user.model->hasAcceptEventTerms()', {eventRuleName, competitionName});

    if (!eventRuleName) {
      throw new Error(`'eventRuleName' must be provided`);
    }

    if (!competitionName) {
      throw new Error(`'competitionName' must be provided`);
    }

    const slugName = eventRuleName.toUpperCase().replace(/\s|-|\./g, '_');

    competitionName = competitionName.replace(/\s|-/g, '_');
    competitionName = competitionName.toUpperCase();
    // console['logger'].log('user.model->hasAcceptEventTerms(): competitionName', competitionName);

    // ROLE_EVENT_ACCEPTED_TERMS_{eventName}
    const ruleName = `ROLE_EVENT_ACCEPTED_TERMS${competitionName ? '_' + competitionName : ''}_${slugName}`;
    // console['logger'].log('user.model->hasAcceptEventTerms(): ruleName', this.rolesEvents, ruleName, this.rolesEvents.indexOf(ruleName) > -1);

    // console.trace('user.model->hasAcceptEventTerms()');

    return this.rolesEvents.indexOf(ruleName) > -1;
  }

  /**
   * Method to check if the user has pass through all event requiments
   */
  hasSignedUpToParticipateEvent(eventRuleName, competitionName?) {
    // console['logger'].log('user.model->hasSignedUpToParticipateEvent()', {eventRuleName, competitionName});

    if (!eventRuleName) {
      throw new Error(`'eventRuleName' must be provided`);
    }

    if (!competitionName) {
      throw new Error(`'competitionName' must be provided`);
    }

    return !!(
      // this.hasAcceptedWalletToS &&
      // this.hasAcceptedMonetizationToS &&
      // this.isNetworkValidated(eventRuleName) &&
      this.hasAcceptEventTerms(eventRuleName, competitionName)
    );
  }

  /**
   * Method to validate if the user is a agency
   */
  isAgency() {
    return this.roles.indexOf('ROLE_AGENCY') > -1;
  }

  // USER AGENCY DASHBOARD
  isAgencyDashboard() {
    return this.roles.indexOf('ROLE_AGENCY_DASHBOARD_USER') > -1;
  }

  isAgencyDashboardAdmin() {
    return this.roles.indexOf('ROLE_AGENCY_DASHBOARD_ADMIN') > -1;
  }

  isAgencyDashboardSuperAdmin() {
    return this.roles.indexOf('ROLE_AGENCY_DASHBOARD_SUPER_USER') > -1;
  }

  isAgencyDashboardUser() {
    return this.isAgencyDashboard() || this.isAgencyDashboardAdmin() || this.isAgencyDashboardSuperAdmin();
  }

  // USER COLLAB DASHBOARD
  isCollabDashboardBrand() {
    return this.roles.indexOf('ROLE_COLLAB_DASHBOARD_BRAND') > -1;
  }

  isCollabDashboardAdmin() {
    return this.roles.indexOf('ROLE_COLLAB_DASHBOARD_ADMIN') > -1;
  }

  isCollabDashboardSuperAdmin() {
    return this.roles.indexOf('ROLE_COLLAB_DASHBOARD_SUPER_USER') > -1;
  }

  isCollabCreator() {
    return this.roles.indexOf('ROLE_COLLAB_DASHBOARD_USER') > -1;
  }

  isCollabDashboardUser() {
    return this.isCollabCreator() || this.isCollabDashboardBrand() || this.isCollabDashboardAdmin() || this.isCollabDashboardSuperAdmin();
  }

  isCollabDashboardAdminReadOnly() {
    return this.roles.indexOf('ROLE_COLLAB_DASHBOARD_ADMIN_READ_ONLY') > -1;
  }

  /**
   * Method to follow the user
   */
  async follow() {
    // console['logger'].log('user.model->follow()');

    try {

      this.hasJustFollowed = true;

      await ResourceService.instance.post('api', {
        resource: 'users',
        path: `/user/${this.username}/follower-user`
      });

      setTimeout(() => {
        this.isFollowing = true;
        this.hasJustFollowed = false;
      }, 2000);

      return this;

    }
    catch (e) {
      this.hasJustFollowed = false;
      this.isFollowing = false;
      throw e;
    }

  }

  /**
   * Method to unfollow the user
   */
  async unfollow() {
    // console['logger'].log('user.model->unfollow()');

    try {

      this.hasJustUnfollowed = true;

      await ResourceService.instance.delete('api', {
        resource: 'users',
        path: `/user/${this.username}/follower-user`
      });

      setTimeout(() => {
        this.isFollowing = false;
        this.hasJustUnfollowed = false;
      }, 2000);

      return this;

    }
    catch (e) {
      this.hasJustUnfollowed = false;
      this.isFollowing = true;
      throw e;
    }
  }

  /**
   * Method to return the user posts
   */
  async getPosts(params?: { limit?: number, lastId?: number }): Promise<{ lastId, totalCount, list }> {
    // console['logger'].log('user.model->getPosts(): params', params);

    try {

      const finalList = [];

      const response = await ResourceService.instance.get('api', {
        resource: 'posts',
        path: `/user/${this.username}/posts`,
        params
      });
      console['logger'].log('user.model->getPosts(): response', response);

      for (const post of response.items) {
        if (post.score !== 0) {
          finalList.push(post);
        }
      }

      return {
        lastId: response.lastId,
        totalCount: response.totalCount,
        list: ModelFactoryService.instance.postFactory.buildCollection(finalList) || []
      };

    }
    catch (e) {
      throw e;
    }

  }

  /**
   * Method to return the users that have followed the profile
   */
  async getUserFollowers(params?: { limit?: number, lastId?: number }): Promise<{ lastId, totalCount, list }> {
    // console['logger'].log('user.model->getUserFollowers(): params', params);

    try {

      const response = await ResourceService.instance.get('api', {
        resource: 'users',
        path: `/user/${this.id}/followers`,
        params
      });

      return {
        lastId: response.lastId,
        totalCount: response.totalCount,
        list: ModelFactoryService.instance.userFactory.buildCollection(response.items)
      };

    }
    catch (e) {
      throw e;
    }

  }

  /**
   * Method to return the users that the profile has followed
   */
  async getUserFollowing(params?: { limit?: number, lastId?: number }): Promise<{ lastId, totalCount, list }> {
    // console['logger'].log('user.model->getUserFollowing(): params', params);

    try {

      const response = await ResourceService.instance.get('api', {
        resource: 'users',
        path: `/user/${this.id}/following`,
        params
      });

      return {
        lastId: response.lastId,
        totalCount: response.totalCount,
        list: ModelFactoryService.instance.userFactory.buildCollection(response.items)
      };

    }
    catch (e) {
      throw e;
    }

  }

  /**
   * Method to add the SIGNIN role to the user and add to the Active Campaign list
   */
  async signInWallet() {
    // console['logger'].log('user.model->signInWallet()');
    try {

      if (this.hasAcceptedWalletToS) {
        return true;
      }

      if (moment(this.getSettings('birthday')).isAfter(moment().subtract(18, 'years'))) {
        this.isUnderage = true;
        throw new Error('birthday.underage');
      }

      const response = await ResourceService.instance.post('api', {
        resource: 'users',
        path: `/user/wallet/accept-terms-of-service`
      });

      // console['logger'].log('user.model->signInWallet(): response', response);

      this.fill(response);

      CacheService.instance.clearCache('users');

    }
    catch (e) {
      console.error('user.model->signInWallet(): ERROR', e);
      throw e;
    }
  }

  /**
   * Method to create or change a setting to the user
   */
  async saveSettings(data: UserSettingsInterface, settingId?: number) {
    try {

      let setting = null;

      if (settingId) {

        setting = this.getSettingById(settingId);

        if (!setting) {
          throw new Error(`Setting '${settingId}' does not exist`);
        }

      }

      const headers = { Authorization: `Bearer ${this.apiToken}` };

      let response;

      if (settingId) {

        response = await ResourceService.instance.put('api', {
          resource: 'users',
          path: `/user/settings/${settingId}`,
          headers,
          data
        });

      }
      else {

        response = await ResourceService.instance.post('api', {
          resource: 'users',
          path: `/user/settings`,
          headers,
          data
        });

      }

      await this.fill(response);
      console['logger'].log(`user.model->saveSettings(): this`, this);

      return this;

    }
    catch (e) {
      console.error(`user.model->saveSettings(): ERROR`, e);
      throw e;
    }
  }

}

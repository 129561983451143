import {
  Inject,
  Injectable,
  Optional
} from '@angular/core';
import { utilsFactory } from '@factories/utils.factory';
import {
  REQUEST,
  RESPONSE
} from '@nguniversal/express-engine/tokens';
import Cookies from 'js-cookie';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  ssrCookies = {};

  constructor(
    @Optional() @Inject(REQUEST) private request: any,
    @Optional() @Inject(RESPONSE) private response: any
  ) {
    console['logger'].log('cookie.service->constructor()');
  }

  /**
   * Method to get all cookies
   */
  getCookies(): any {
    // console['logger'].log('cookie.service->getCookies()');

    try {

      const cookies = {};
      let cookieParsed = {};

      if (this.request) {
        cookieParsed = this.request.cookies;
      }
      else {
        cookieParsed = Cookies.get();
      }

      // console['logger'].log('cookie.service->getCookies(): cookieParsed', cookieParsed);

      // tslint:disable-next-line:forin
      for (const i in cookieParsed) {
        if (cookieParsed[i]) {
          if (cookieParsed[i].indexOf('{') === 0 || cookieParsed[i].indexOf('[') === 0) {
            cookies[i] = JSON.parse(cookieParsed[i]);
          }
          else {
            cookies[i] = cookieParsed[i];
          }
        }
      }

      // console['logger'].log('cookie.service->getCookies(): cookies', cookies);

      return cookies;

    }
    catch (e) {
      console.error('cookie.service->getCookies(): ERROR', e);
      throw e;
    }
  }

  /**
   * Method to get a cookie
   */
  getCookie(name: string): any {
    // console['logger'].log('cookie.service->getCookie(): name', name);
    try {

      const cookies = this.getCookies();
      // console['logger'].log('cookie.service->getCookie(): cookies', cookies);

      let value = null;

      if (name in cookies) {
        value = cookies[name];
      }

      // console['logger'].log('cookie.service->getCookie(): value', value);

      return value;

    }
    catch (e) {
      // console.error('cookie.service->getCookie()', e);
      throw e;
    }
  }

  /**
   * Method to set a cookie
   */
  setCookie(
    name,
    value: Array<any> | object | string,
    days?: number,
    sameSite?: boolean | 'lax' | 'strict' | 'none',
    secure?: boolean,
    path = '/'
  ) {
    try {

      /*console['logger'].info('cookie.service->setCookie(): ', {
       name,
       value,
       days,
       sameSite,
       secure,
       path
       });*/

      if (value === null || value === undefined) {
        throw new Error(`'value' must be provided`);
      }

      const finalValue = typeof value === 'object' ? JSON.stringify(value) : value;

      if (utilsFactory.isBrowser) {

        Cookies.set(name, finalValue, {
          expires: days,
          sameSite,
          secure,
          path
        });

      }
      else if (utilsFactory.isSSR) {

        const expiresDate = moment().locale('en').add(days, 'days').format();
        console['logger'].info('cookie.service->setCookie(): expiresDate', expiresDate);

        this.ssrCookies[name] = `${name}=${finalValue}; Expires=${expiresDate}; Path=${path};`;
        // console['logger'].info('cookie.service->setCookie(): this.ssrCookies[name]', this.ssrCookies[name]);

        console['logger'].info('cookie.service->setCookie(): this.ssrCookies', this.ssrCookies);

        const cookies = [];

        for (const key in this.ssrCookies) {
          cookies.push(this.ssrCookies[key]);
        }

        this.response.setHeader('Set-Cookie', cookies);

      }
    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method to remove a cookie value
   */
  removeCookie(name: string) {
    try {

      // console['logger'].log('cookie.service->removeCookie(): name', name);

      // const cookie = this.getCookie(name);
      // console['logger'].log('cookie.service->removeCookie(): cookie', cookie);

      if (utilsFactory.isBrowser) {

        // force removing all duplicated values from cookies
        for (let i = 0; i <= 5; i++) {
          // document.cookie = name + '=; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
          // this.setCookie(name, '');
          Cookies.remove(name, {path: '/'});
        }
      }

    }
    catch (e) {
      throw e;
    }
  }

}

import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  AvatarInterface,
  AvatarModel,
  UserModel
} from '@api/models';
import { environment } from '@environments/environment';
import { utilsFactory } from '@factories/utils.factory';
import { MobileDetectService } from '@services/mobile-detect/mobile-detect.service';

@Component({
  selector: 'mip-avatar,[mip-avatar]',
  templateUrl: './mip-avatar.component.html',
  styleUrls: ['./mip-avatar.component.scss']
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class MipAvatarComponent implements OnInit, OnChanges, AfterViewInit {

  @ViewChild('avatar') avatarEl: ElementRef;

  @Input() userModel: UserModel = null;

  @Input() avatar: AvatarInterface = null;
  @Input() source: string = null;
  @Input() username: string = null;
  @Input() size: string | number | Array<number> = '2x';
  @Input() fontSize = '14px';

  @Input() border: boolean | number = true;
  @Input() borderRadius = '50%';
  @Input() borderColor = '';
  @Input() borderBgColor = '';
  @Input() borderWidth: number = null;
  @Input() borderOpacity = 100;

  @Input() elevation: number;
  @Input() enablePlaceholder = true;
  @Input() enableBgColor = true;
  @Input() prefix = true;
  @Input() crown: string = null;

  @Input() roundProgress = false;
  @Input() roundProgressRadius = 22;
  @Input() roundProgressStroke = 4;
  @Input() roundProgressCurrent = 100;
  @Input() roundProgressMax = 100;
  @Input() roundProgressDuration = 800;

  @Input() bgColor = null;

  @Input() spin = false;

  assetsPath = environment.assetsPath;

  roundProgressMargin = 0;
  iconStyles: { width?, height?, borderWidth? } = { borderWidth: '1px' };
  bdClasses = [];
  userPrefix = null;
  userPrefixFontSize = null;
  avatarElement = null;
  sourceUrl = null;
  userModelSourceUrl = null;

  isDesktop = false;

  constructor(
    private mobileDetect: MobileDetectService
  ) {
    // const colorNumber = utilsFactory.randomNumber(0, this.colors.length);
    // const color = this.colors[colorNumber];
    // this.bgColor = `mip-color--bg-${color}`;

    this.isDesktop = !this.mobileDetect.isMobile();
  }

  ngOnInit() {

    if (!this.source && !this.userModel) {
      // throw new Error(``);
    }

    // console['logger'].log('mip-avatar.component->ngOnInit() this.crown', this.crown);

  }

  ngOnChanges(changes: SimpleChanges) {

    // console['logger'].log('avatar.component->ngOnChanges() changes', changes);

    // this.sourceUrl = null;
    // this.iconStyles = {};
    // this.bdClasses = [];
    // this.userPrefix = null;
    // this.bgColor = null;

    if (changes['roundProgressStroke']) {
      if (this.roundProgress) {
        // console['logger'].log('avatar.component->ngOnChanges() changes.roundProgress', changes.roundProgress);
        this.roundProgressMargin = Math.ceil(this.roundProgressStroke / 2);
      }
    }

    if (changes['border']) {
      // console['logger'].log('avatar.component->ngOnChanges() changes.border', changes.border);

      if (typeof this.border === 'number') {
        this.iconStyles.borderWidth = this.border + 'px';
      }
      else if (this.border === false) {
        this.iconStyles.borderWidth = '0px';
      }
      else {
        this.iconStyles.borderWidth = '1px';
      }
    }

    if (changes['size']) {
      // console['logger'].log('avatar.component->ngOnChanges() changes.size', changes.size);

      if (this.size === 'auto') {
        // do nothing
      }
      else if (Array.isArray(this.size)) {

        const width = this.size[0];
        const height = this.size[1] || width;

        this.iconStyles.width = width + 'px';
        this.iconStyles.height = height + 'px';

      }
      else if (typeof this.size === 'number') {
        this.iconStyles.width = this.size + 'px';
        this.iconStyles.height = this.size + 'px';
      }
      else if (typeof this.size === 'string' && parseInt(this.size, 10) > 15) {
        this.size = '15x';
      }
    }

    if (changes['source']) {
      // console['logger'].log('avatar.component->ngOnChanges() changes.source', changes.source);
      this.sourceUrl = this.source;
    }
    else if (changes['avatar']) {
      // console['logger'].log('avatar.component->ngOnChanges() changes.avatar', changes.avatar);

      if (this.avatar instanceof AvatarModel) {
        this.sourceUrl = this.avatar.source;
      }
    }

    console.log('avatar.component->ngOnChanges() changes.borderColor', changes['borderColor']);

    if (changes['borderColor'] && this.borderColor) {
      // console['logger'].log('avatar.component->ngOnChanges() changes.borderColor', changes.borderColor);
      this.bdClasses.push(`mip-color--bd-${this.borderColor}`);
    }

    if (changes['borderBgColor'] && this.borderBgColor) {
      // console['logger'].log('avatar.component->ngOnChanges() changes.borderBgColor', changes.borderBgColor);
      this.bdClasses.push(`mip-color--bg-${this.borderBgColor}`);
    }

    if (changes['borderWidth'] && this.borderWidth !== null) {
      // console['logger'].log('avatar.component->ngOnChanges() changes.borderWidth', changes.borderWidth);
      this.bdClasses.push(`--bd-size-${this.borderWidth}`);
    }

    if (changes['userModel']) {
      // console['logger'].log('avatar.component->ngOnChanges() changes.userModel', changes.userModel);
      // console['logger'].log('avatar.component->ngOnChanges() this.userModel.username', this.userModel.username);
      // console['logger'].log('avatar.component->ngOnChanges() this.userModel.avatar', this.userModel.avatar, typeof this.userModel.avatar);

      if (this.userModel) {

        if (this.prefix) {
          this.userPrefix = this.getPrefix(this.userModel.name || this.userModel.username);
          // console['logger'].log('mip-avatar.component->ngOnChanges(): this.userPrefix', this.userPrefix);
        }

        if (
          this.userModel instanceof UserModel &&
          this.userModel.avatar instanceof AvatarModel
        ) {
          this.userModelSourceUrl = this.userModel.avatar.source;
        }
        else {
          this.userModelSourceUrl = null;
        }

        // console['logger'].log('mip-avatar.component->ngOnChanges(): this.sourceUrl', this.sourceUrl);
        // console['logger'].log('mip-avatar.component->ngOnChanges(): this.userModelSourceUrl', this.userModelSourceUrl);

        if (this.enableBgColor) {
          this.bgColor = this.userModel.randomBgColor;
          // console['logger'].log('mip-avatar.component->ngOnInit(): this.bgColor', this.bgColor);
        }

      }

    }

    if (this.username) {
      if (this.prefix) {
        this.userPrefix = this.getPrefix(this.username);
        // console['logger'].log('mip-avatar.component->ngOnChanges(): this.userPrefix', this.userPrefix);
      }
    }

    // console['logger'].log('avatar.component->ngOnChanges() this.sourceUrl', this.sourceUrl);
    // console['logger'].log('avatar.component->ngOnChanges() this.userModel', this.userModel instanceof UserModel);
    // console['logger'].log('avatar.component->ngOnChanges() this.userModel.avatar', this.userModel.avatar instanceof AvatarModel);

    console.log('avatar.component->ngOnChanges() this.bdClasses', this.bdClasses);

  }

  ngAfterViewInit() {

    if (this.avatarEl && utilsFactory.isBrowser) {
      this.avatarElement = this.avatarEl.nativeElement;

      // if (this.userPrefix) {
      setTimeout(() => {
        if (this.avatarElement.offsetWidth) {
          this.userPrefixFontSize = `${this.avatarElement.offsetWidth * 0.6}px`;
          // console['logger'].log('mip-avatar.component->ngAfterViewInit(): this.userPrefixFontSize', this.userPrefixFontSize);
        }
      }, 100);
      // }

    }

  }

  getClasses() {
    const classes = {};

    if (typeof this.size === 'string') {
      classes['--size-' + this.size] = true;
    }

    if (this.elevation) {
      classes['mat-elevation-z' + this.elevation] = true;
    }

    /*if (this.bgColor) {
     classes[this.bgColor] = true;
     }*/

    /*if (this.bdColor) {
     classes[this.bdColor] = true;
     }*/

    return classes;
  }

  getPrefix(username: string) {
    // console['logger'].log('mip-avatar.component->getPrefix(): username', username);

    const usernameSplit = username.split('');
    // console['logger'].log('mip-avatar.component->getPrefix(): usernameSplit', usernameSplit);

    let userPrefix = '';

    for (const i in usernameSplit) {
      if (/[A-z]/.test(usernameSplit[i])) {
        userPrefix = usernameSplit[i].toUpperCase();
        break;
      }
    }

    // console['logger'].log('mip-avatar.component->getPrefix(): userPrefix', userPrefix);

    return userPrefix;

  }

}
